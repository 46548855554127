import React, { useState } from 'react'
import { StyledCoffee } from './style'

const Coffee = ({ coffee, handleChange }) => {
  const style = {
    '--gradient-from': coffee.gradient_from_color,
    '--gradient-mid': coffee.gradient_mid_color,
    '--gradient-to': coffee.gradient_to_color
  }

  return(
    <StyledCoffee className="coffee">
      <div className="name">
        <input type="checkbox" value={coffee} onChange={(e) => { handleChange(e, coffee) }} /> {coffee.name}
      </div>
      
      <span className="degrade" style={style}></span>

      <div className="items">
        <div className="item">
        <div className="title">Fazenda</div>
          <div className="value">{coffee.farm}</div>
        </div>

        <div className="item">
          <div className="title">Origem</div>
          <div className="value">{coffee.origin} {coffee.region ? `(${coffee.region})` : ''}</div>
        </div>

        <div className="item">
          <div className="title">Sabor</div>
          <div className="value">{coffee.sweetness}</div>
        </div>

        <div className="item">
          <div className="title">Perfil de torra</div>
          <div className="value">{coffee.roast_profile}</div>
        </div>

        <div className="item">
          <div className="title">Altitude</div>
          <div className="value">{coffee.altitude}</div>
        </div>
        
        <div className="item">
          <div className="title">Processamento</div>
          <div className="value">{coffee.praparation_process}</div>
        </div>
      </div>

      <div className="actions">
        <a href={`/cafes/${coffee.id}`}>Visualizar</a>
      </div>
    </StyledCoffee>
  )
}

export default Coffee
