import styled from 'styled-components'

export const StyledCoffees = styled.div`
  margin-top: 50px;

  .actions {
    text-align: right;
    margin: 0 0 30px;

    button {
      background: ${({ theme }) => theme.colors.primary};
      border: none;
      font-size: ${props => props.theme.fontSizes.small};
      color: #fff;
      cursor: pointer;
      padding: 15px;
      border-radius: ${props => props.theme.borderRadius};
      text-align: center;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        background: ${({ theme }) => theme.colors.darkenPrimary};
      }
    }
  }

  .coffees {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .coffee {
    width: 24%;
    margin-bottom: 2%;
  }
`