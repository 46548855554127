import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledCoffees } from './style'

import Coffee from '../../components/coffee'

const Coffees = () => {
  const coffees = useSelector((state) => state.coffees)
  const [checked, setChecked] = useState([])

  const handleChange = (e, coffee) => {
    let updatedList = [...checked]

    if (e.target.checked) {
      updatedList = [...checked, coffee]
    } else {
      updatedList.splice(checked.indexOf(coffee), 1)
    }

    setChecked(updatedList)
  }

  const handleSimulatorClick = () => {
    if(checked.length){
      const ids = checked.map((coffee) => coffee.id)
 
      window.open(`/simulador/?ids=${ids.join(',')}`, '_blank')
    }
  }

  return(
    <StyledCoffees>
      <div className="actions">
        <button type="button" onClick={handleSimulatorClick}>Simular</button>
      </div>

      <div className="coffees">
        {coffees.map((coffee, i) => {
          return <Coffee coffee={coffee} handleChange={handleChange} key={i} />
        })}
      </div>
    </StyledCoffees>
  )
}

export default Coffees