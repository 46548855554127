import React, { useEffect } from 'react'
import api from '../../services/api'
import { useDispatch } from 'react-redux'
import { updateCoffees } from '../../state/reducers/coffees'
import { showLoading, hideLoading } from '../../state/reducers/loading'

import Layout from '../../components/layout'
import Container from '../../components/container'
import Coffees from '../../components/coffees'

const CoffeesIndex = () => {
  const dispatch = useDispatch();

  const requestCoffees = () => {
    dispatch(showLoading('Carregando...'))

    api.get(`/coffees`).then((response) => {
      if(response.data.coffee_beans){
        dispatch(updateCoffees(response.data.coffee_beans))
      }

      dispatch(hideLoading())
    }).catch(() => {
      dispatch(hideLoading())
    })
  }

  useEffect(() => {
    requestCoffees()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Layout>
      <Container>
        <Coffees />
      </Container>
    </Layout>
  )
}

export const Head = () => {
  return(
    <title>
      Cafés
    </title>
  )
}

export default CoffeesIndex
