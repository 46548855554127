import styled from 'styled-components'

export const StyledCoffee = styled.div`
  background: #fff;
  padding: 25px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 5px 13px rgb(0 0 0 / 20%);
  border: 2px #FFF solid;
  line-height: 1;
  position: relative;

  .name {
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes.large};
  }

  .degrade {
    background: var(--gradient-to);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
    background: -moz-linear-gradient(45deg, var(--gradient-to) 0%, var(--gradient-mid) 50%, var(--gradient-from) 100%);
    background: -webkit-linear-gradient(45deg, var(--gradient-to) 0%, var(--gradient-mid) 50%, var(--gradient-from) 100%);
    background: linear-gradient(45deg, var(--gradient-to) 0%, var(--gradient-mid) 50%, var(--gradient-from) 100%);
    display: block;
    height: 10px;
    border-radius: ${props => props.theme.borderRadius};
    margin: 20px 0;
  }

  .items {
    .item {
      .title {
        text-transform: uppercase;
        font-size: 11px;
        opacity: 0.7;
      }

      .value {
        font-weight: 600;
        margin-top: 5px;
        font-size: ${props => props.theme.fontSizes.small};
      }

      & + .item {
        margin-top: 20px;
      }
    }
  }

  .actions {
    margin-top: 25px;

    a {
      background: ${({ theme }) => theme.colors.primary};
      font-size: ${props => props.theme.fontSizes.small};
      color: #fff;
      padding: 15px;
      display: block;
      border-radius: ${props => props.theme.borderRadius};
      text-align: center;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        background: ${({ theme }) => theme.colors.darkenPrimary};
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}) {
    
  }
`